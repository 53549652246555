.wrapper {
  text-align: center;
}
.wrapper .img {
  margin: auto;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin-bottom: 20px;
}
.wrapper .img i {
  font-size: 25px;
}
.wrapper .product {
  padding: 30px;
}
.wrapper .product h3 {
  font-weight: 500;
}
.wrapper p {
  font-size: 15px;
  margin-top: 20px;
  color: grey;
}
